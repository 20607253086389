import { route_prefix } from "configs";

export const routeConstants = {
	root: {
		route: `${route_prefix}/`,
		name: "Home",
	},
	waitlist: {
		route: `${route_prefix}/waitlist`,
		name: "Waitlist",
	},
	userManagement: {
		route: `${route_prefix}/user-management`,
		name: "User Management",
	},
	userManagementDetails: {
		route: `${route_prefix}/user-management-details`,
		name: "User Management Details",
	},
	config: {
		route: `${route_prefix}/configs`,
		name: "Configs",
	},
	products: {
		route: `${route_prefix}/products`,
		name: "Products",
	},
	inspiration: {
		route: `${route_prefix}/inspiration`,
		name: "Inspiration",
	},
	login: {
		route: `${route_prefix}/login`,
		name: "Login",
	},
	logout: {
		route: `${route_prefix}/logout`,
		name: "Logout",
	},
};
