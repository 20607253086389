import React from "react";
import { OrderStatusProps } from "./OrderStatus.props";

const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
	switch (status) {
		case "Waiting":
			return (
				<button className="px-2 py-1 font-medium bg-[#FFFAEB] rounded-2xl text-[#FE9A04]">
					Waiting
				</button>
			);
		case "Preparing":
			return (
				<button className="bg-[#FFF6ED] px-2 py-1 font-medium rounded-2xl text-[#C4320A]">
					Preparing
				</button>
			);
		case "Delivered":
			return (
				<button className="bg-[#ECFDF3] px-2 py-1 font-medium rounded-2xl text-[#28C76F]">
					Delivered
				</button>
			);
		case "Cancelled":
			return (
				<button className="bg-[#FEF3F2] px-2 py-1 font-medium rounded-2xl text-redColor">
					Cancelled
				</button>
			);
		case "Returned":
			return (
				<button className="bg-[#F2F4F7] px-2 py-1 font-medium rounded-2xl text-[#344054]">
					Returned
				</button>
			);
		default:
			return null;
	}
};

export default OrderStatus;
