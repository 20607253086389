import React from "react";
import { StyleManagerItemProps } from "./StyleManagerItem.props";
import { P } from "common/P/P";
import { ReactComponent as EditIcon } from "common/assets/icons/edit-pencil.svg";
import { ReactComponent as DeleteIcon } from "common/assets/icons/trash.svg";

const StyleManagerItem: React.FC<StyleManagerItemProps> = ({
	label,
	paddingY = "py-1",
	onClick,
	className,
	onEdit,
	onDelete,
	bgColor = " bg-whiteColor",
}) => {
	return (
		<div
			className={`border ${
				onEdit && onDelete
					? "grid grid-cols-maxTwo gap-2 place-items-center"
					: "grid grid-cols-1"
			} rounded-md border-strokeColor ${bgColor} ${paddingY} px-4 cursor-default ${className}`}
			onClick={onClick}
		>
			<P size="p2" type="medium" className="cursor-pointer">
				{label}
			</P>
			{onEdit && onDelete && (
				<div className="flex gap-2 pl-2 border-l border-strokeColor">
					<EditIcon
						className="w-5 h-5 cursor-pointer stroke-textColor"
						onClick={onEdit}
					/>
					<DeleteIcon
						className="w-5 h-5 cursor-pointer stroke-textColor"
						onClick={onDelete}
					/>
				</div>
			)}
		</div>
	);
};

export default StyleManagerItem;
