import React, { useLayoutEffect } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { ReactComponent as AlertIcon } from "common/assets/icons/alert.svg";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { motion } from "framer-motion";
import { ModalProps } from "./Modal.props";
import { P } from "common/P/P";

const Modal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	title,
	onConfirm,
	onCancel,
	desc,
	width,
}) => {
	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	const modalVariants = {
		open: { opacity: 1, transition: { duration: 0.3 } },
		closed: { opacity: 0, transition: { duration: 0.3 } },
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-40" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					initial="closed"
					animate="open"
					exit="closed"
					variants={modalVariants}
					className={`fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 ${
						width ? width : "w-[610px]"
					} bg-whiteColor flex flex-col z-50 rounded-lg shadow-lg`}
				>
					<div className="flex items-center p-4 bg-white rounded-t-lg place-self-end ">
						<CloseIcon onClick={onClose} className="cursor-pointer" />
					</div>
					<div className="grid gap-3">
						<div className="grid flex-grow gap-8 px-20 py-8 overflow-y-auto place-content-center place-items-center">
							<AlertIcon />
							<div className={`${desc && "grid gap-3"}`}>
								<Htag
									tag="h3"
									type="medium"
									className="text-center cursor-default text-passiveTextColor"
								>
									{title}
								</Htag>
								{desc && (
									<P
										size="p2"
										type="regular"
										className="text-center text-redColor"
									>
										{desc}
									</P>
								)}
							</div>
						</div>
						<div className="grid w-full gap-6 px-8 pb-16 bg-white rounded-b-lg grid-cols-maxTwo place-items-center place-content-center">
							<Button appearance="white" onClick={onCancel}>
								No
							</Button>
							<Button appearance="main" onClick={onConfirm}>
								Yes
							</Button>
						</div>
					</div>
				</motion.div>
			)}
		</>
	);
};

export default Modal;
