import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import Modal from "common/Modal/Modal";
import ImageUpload from "common/Image/ImageUpload";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { SearchInput } from "common/Search/Search";
import { serviceConfig } from "configs";
import formatDate from "utils/formatDate";
import ProductCard from "components/ProductCard/ProductCard";

type Image = {
	filename: string;
	savedImgPath: string;
	index: number;
	_id: string;
};

type RefType = {
	_id: string;
	name: string;
	createdAt: string;
	updatedAt: string;
	image?: string;
};

type Product = {
	_id: string;
	name: string;
	description: string;
	price: number;
	discountPrice?: number;
	discountPercentage?: number;
	images: Image[];
	productType: RefType;
	length: RefType;
	shape: RefType;
	size: RefType;
	collectionType: RefType;
	color: RefType[];
	stock: number;
};

export type ImageFile = File | { url: string; filename: string; index: number };

const ProductList: React.FC = () => {
	const [products, setProducts] = useState<Product[]>([]);
	const [productTypes, setProductTypes] = useState([]);
	const [lengths, setLengths] = useState([]);
	const [shapes, setShapes] = useState([]);
	const [sizes, setSizes] = useState([]);
	const [collectionTypes, setCollectionTypes] = useState([]);
	const [colors, setColors] = useState([]);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [currentProduct, setCurrentProduct] = useState<Product | null>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingProductId, setDeletingProductId] = useState<string | null>(
		null
	);
	const [formValues, setFormValues] = useState({
		name: "",
		description: "",
		price: "",
		discountPrice: "",
		discountPercentage: "",
		productType: "",
		length: "",
		shape: "",
		size: "",
		collectionType: "",
		color: [] as string[],
		stock: "",
	});
	const [uploadedImages, setUploadedImages] = useState<ImageFile[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>("");

	const fetchInitialData = async () => {
		try {
			const [
				productTypesResponse,
				lengthsResponse,
				shapesResponse,
				sizesResponse,
				collectionsResponse,
				colorsResponse,
			] = await Promise.all([
				fetch(`${serviceConfig.apiUrl}/config/productType`),
				fetch(`${serviceConfig.apiUrl}/config/lengthType`),
				fetch(`${serviceConfig.apiUrl}/config/shape`),
				fetch(`${serviceConfig.apiUrl}/config/sizeType`),
				fetch(`${serviceConfig.apiUrl}/config/collectionType`),
				fetch(`${serviceConfig.apiUrl}/config/colorType`),
			]);

			const productTypesData = await productTypesResponse.json();
			const lengthsData = await lengthsResponse.json();
			const shapesData = await shapesResponse.json();
			const sizesData = await sizesResponse.json();
			const collectionsData = await collectionsResponse.json();
			const colorsData = await colorsResponse.json();

			setProductTypes(productTypesData.data);
			setLengths(lengthsData.data);
			setShapes(shapesData.data);
			setSizes(sizesData.data);
			setCollectionTypes(collectionsData.data);
			setColors(colorsData.data);
		} catch (error) {
			console.error("Failed to fetch initial data:", error);
		}
	};

	const fetchProducts = async (search = "") => {
		try {
			const response = await fetch(
				`${serviceConfig.apiUrl}/products${search ? `/search?q=${search}` : ""}`
			);
			const data = await response.json();
			if (data.success) {
				setProducts(data.data);
			} else {
				console.error(data.message);
			}
		} catch (error) {
			console.error("Error fetching products:", error);
		}
	};

	useEffect(() => {
		fetchProducts();
		fetchInitialData();
	}, []);

	const handleAddProduct = () => {
		setCurrentProduct(null);
		setFormValues({
			name: "",
			description: "",
			price: "",
			discountPrice: "",
			discountPercentage: "",
			productType: "",
			length: "",
			shape: "",
			size: "",
			collectionType: "",
			color: [],
			stock: "",
		});
		setUploadedImages([]);
		setIsManagePanelOpen(true);
	};

	const handleEditProduct = (product: Product) => {
		setCurrentProduct(product);
		setFormValues({
			name: product.name,
			description: product.description,
			price: product.price.toString(),
			discountPrice: product.discountPrice?.toString() || "",
			discountPercentage: product.discountPercentage?.toString() || "",
			productType: product.productType._id,
			length: product.length._id,
			shape: product.shape._id,
			size: product.size._id,
			collectionType: product.collectionType._id,
			color: product.color.map((c) => c._id),
			stock: product.stock.toString(),
		});
		// Separate existing images and new images
		const existingImages = product.images.map((img) => ({
			url: img.savedImgPath,
			filename: img.filename,
			index: img.index,
		}));
		setUploadedImages(existingImages);
		setIsManagePanelOpen(true);
	};

	const closeManagePanel = () => {
		setCurrentProduct(null);
		setIsManagePanelOpen(false);
	};

	const submitProduct = async () => {
		const formData = new FormData();

		uploadedImages.forEach((img, index) => {
			if (img instanceof File) {
				formData.append(`images[${index}]`, img);
			} else {
				formData.append(
					`existingImages`,
					JSON.stringify({ url: img.url, index: img.index })
				);
			}
		});

		Object.entries(formValues).forEach(([key, value]) => {
			formData.append(
				key,
				Array.isArray(value) ? value.join(",") : value.toString()
			);
		});

		if (currentProduct) {
			const response = await fetch(
				`${serviceConfig.apiUrl}/products/${currentProduct._id}`,
				{
					method: "PUT",
					body: formData,
				}
			);
			const data = await response.json();
			if (data.success) {
				setProducts((prevProducts) =>
					prevProducts.map((p) =>
						p._id === currentProduct._id ? data.data : p
					)
				);
				closeManagePanel();
			} else {
				console.error(data.message);
			}
		} else {
			const response = await fetch(`${serviceConfig.apiUrl}/products`, {
				method: "POST",
				body: formData,
			});
			const data = await response.json();
			if (data.success) {
				setProducts((prevProducts) => [...prevProducts, data.data]);
				closeManagePanel();
				window.location.reload();
			} else {
				console.error(data.message);
			}
		}
	};

	const handleDeleteProduct = (productId: string) => {
		setDeletingProductId(productId);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteProduct = async () => {
		if (!deletingProductId) return;

		const response = await fetch(
			`${serviceConfig.apiUrl}/products/${deletingProductId}`,
			{
				method: "DELETE",
			}
		);
		const data = await response.json();
		if (data.success) {
			setProducts((prevProducts) =>
				prevProducts.filter((product) => product._id !== deletingProductId)
			);
			setIsDeleteModalOpen(false);
		} else {
			console.error(data.message);
		}
	};

	const handleSearch = async () => {
		if (searchQuery.length === 0) {
			fetchProducts();
			return;
		}
		fetchProducts(searchQuery);
	};

	const handleSelectConfig = (key: string, value: string) => {
		setFormValues((prev) => {
			if (key === "color") {
				const colors = new Set(prev.color);
				if (colors.has(value)) {
					colors.delete(value);
				} else {
					colors.add(value);
				}
				return {
					...prev,
					color: Array.from(colors),
				};
			} else {
				return {
					...prev,
					[key]: value,
				};
			}
		});
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Product List
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Track, manage and forecast your products and orders.
					</P>
				</div>
				<Button
					appearance="main"
					icon={<PlusIcon />}
					onClick={handleAddProduct}
				>
					Add Product
				</Button>
			</div>
			<div className="grid grid-cols-1 gap-4">
				<SearchInput
					value={searchQuery}
					placeholder="Search"
					onChange={(e) => setSearchQuery(e.target.value)}
					handleSearch={handleSearch}
				/>
				{products.map((product) => (
					<ProductCard
						key={product._id}
						imageSrc={product.images[0]?.savedImgPath}
						name={product.name}
						description={product.description}
						price={product.price}
						discountPrice={product.discountPrice}
						discountPercentage={product.discountPercentage}
						productType={product.productType.name}
						length={product.length.name}
						shape={product.shape.name}
						size={product.size.name}
						collection={product.collectionType.name}
						color={product.color.map((c) => c.name).join(", ")}
						stock={product.stock}
						onEdit={() => handleEditProduct(product)}
						onDelete={() => handleDeleteProduct(product._id)}
					/>
				))}
			</div>
			<ManagePanel
				isOpen={isManagePanelOpen}
				onClose={closeManagePanel}
				label={currentProduct ? "Edit Product" : "Add Product"}
				onSave={submitProduct}
				onDiscard={closeManagePanel}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name"
						label="Name"
						placeholder="Enter name..."
						value={formValues.name}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								name: e.target.value,
							}))
						}
					/>
					<Textarea
						withlabel={true}
						appearance="default"
						name="description"
						label="Description"
						placeholder="Enter description..."
						value={formValues.description}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								description: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="price"
						label="Price"
						placeholder="Enter price..."
						value={formValues.price}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								price: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="discountPrice"
						label="Discount Price"
						placeholder="Enter discount price..."
						value={formValues.discountPrice}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								discountPrice: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="discountPercentage"
						label="Discount Percentage"
						placeholder="Enter discount percentage..."
						value={formValues.discountPercentage}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								discountPercentage: e.target.value,
							}))
						}
					/>
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="stock"
						label="Stock"
						placeholder="Enter stock..."
						value={formValues.stock}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								stock: e.target.value,
							}))
						}
					/>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Product Type
						</P>
						<div
							className={`${
								productTypes.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{productTypes.map((type: any) => (
								<StyleManagerItem
									key={type._id}
									label={type.name}
									onClick={() => handleSelectConfig("productType", type._id)}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.productType === type._id
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Length
						</P>
						<div
							className={`${
								lengths.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{lengths.map((length: any) => (
								<StyleManagerItem
									key={length._id}
									label={length.name}
									onClick={() => handleSelectConfig("length", length._id)}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.length === length._id
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Shape
						</P>
						<div
							className={`${
								shapes.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{shapes.map((shape: any) => (
								<StyleManagerItem
									key={shape._id}
									label={shape.name}
									onClick={() => handleSelectConfig("shape", shape._id)}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.shape === shape._id
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Size
						</P>
						<div
							className={`${
								sizes.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{sizes.map((size: any) => (
								<StyleManagerItem
									key={size._id}
									label={size.name}
									onClick={() => handleSelectConfig("size", size._id)}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.size === size._id
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Collection
						</P>
						<div
							className={`${
								collectionTypes.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{collectionTypes.map((collection: any) => (
								<StyleManagerItem
									key={collection._id}
									label={collection.name}
									onClick={() =>
										handleSelectConfig("collectionType", collection._id)
									}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.collectionType === collection._id
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<div className="grid gap-4">
						<P size="p3" type="medium">
							Color
						</P>
						<div
							className={`${
								colors.length > 0
									? "flex flex-wrap gap-3"
									: "place-content-stretch"
							}`}
						>
							{colors.map((color: any) => (
								<StyleManagerItem
									key={color._id}
									label={color.name}
									onClick={() => handleSelectConfig("color", color._id)}
									paddingY="py-2"
									bgColor=""
									className={`${
										formValues.color.includes(color._id)
											? "bg-mainColor text-white"
											: "bg-white text-black"
									} cursor-pointer`}
								/>
							))}
						</div>
					</div>
					<ImageUpload
						singleUpload={false}
						onImageUpload={(files) => {
							const newFiles = Array.isArray(files) ? files : [files];
							setUploadedImages(() => [...newFiles]);
						}}
						dragAndDrop={true}
						label="Product Image"
						initialImages={uploadedImages}
					/>
				</div>
			</ManagePanel>
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				title="Delete Product"
				desc="Are you sure you want to delete this product? This action cannot be undone."
				onConfirm={confirmDeleteProduct}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</div>
	);
};

export default ProductList;
