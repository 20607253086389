import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import Pagination from "components/Pagination/Pagination";
import WaitlistTable from "components/WaitlistTable/WaitlistTable";
import { serviceConfig } from "configs";

const WaitlistPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [waitlists, setWaitlists] = useState([]);
	const [loading, setLoading] = useState(true);
	const [totalPages, setTotalPages] = useState(0);
	const itemsPerPage = 8;

	useEffect(() => {
		const fetchWaitlists = async () => {
			try {
				const response = await fetch(
					`${serviceConfig.apiUrl}${serviceConfig.routes.waitlists}`
				);
				const result = await response.json();
				setWaitlists(result.data);
				setTotalPages(Math.ceil(result.data.length / itemsPerPage));
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch waitlists", error);
				setLoading(false);
			}
		};

		fetchWaitlists();
	}, []);

	const currentWaitlists = waitlists.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	return (
		<div className="p-8">
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					Waitlist
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Tracking the waitlist of users.
				</P>
			</div>
			<div className="grid gap-6">
				{loading ? (
					<p>Loading...</p>
				) : (
					<>
						<WaitlistTable data={currentWaitlists} className="mt-8" />
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={setCurrentPage}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default WaitlistPage;
