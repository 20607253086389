import React, { useEffect, useState } from "react";
import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Button } from "common/Button/Button";
import ManagePanel from "common/ManagePanel/ManagePanel";
import { Input } from "common/Input/Input";
import Textarea from "common/Textarea/Textarea";
import ImageUpload from "common/Image/ImageUpload";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import Modal from "common/Modal/Modal";
import InspirationCard from "components/InspirationCard/InspirationCard";
import { serviceConfig } from "configs";
import { Select } from "common/Select/Select";
import { SelectItem } from "common/Select/SelectItem";

const InspirationPage: React.FC = () => {
	const [inspirations, setInspirations] = useState<any[]>([]);
	const [isManagePanelOpen, setIsManagePanelOpen] = useState(false);
	const [currentInspiration, setCurrentInspiration] = useState<any | null>(
		null
	);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deletingInspirationId, setDeletingInspirationId] = useState<
		number | null
	>(null);
	const [formValues, setFormValues] = useState({
		name: "",
		description: "",
		category: "Trending",
	});
	const [uploadedImageSrc, setUploadedImageSrc] = useState<any>("");

	useEffect(() => {
		// Fetch inspirations
		fetch(`${serviceConfig.apiUrl}/inspirations`)
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setInspirations(data.data);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error fetching inspirations:", error));
	}, []);

	const handleAddInspiration = () => {
		setFormValues({ name: "", description: "", category: "Trending" });
		setCurrentInspiration(null); // reset current inspiration
		setIsManagePanelOpen(true); // open the ManagePanel
	};

	const handleEditInspiration = (inspiration: any) => {
		setCurrentInspiration(inspiration);
		setFormValues({
			name: inspiration.name,
			description: inspiration.description,
			category: inspiration.category,
		});
		setIsManagePanelOpen(true);
	};

	const closeManagePanel = () => {
		setCurrentInspiration(null);
		setIsManagePanelOpen(false);
	};

	const editInspiration = (inspiration: any) => {
		const formData = new FormData();

		if (uploadedImageSrc instanceof File) {
			formData.append("image", uploadedImageSrc); // Append file if new image uploaded
		}

		formData.append("name", formValues.name || inspiration.name); // Keep existing if not updated
		formData.append(
			"description",
			formValues.description || inspiration.description
		);
		formData.append("category", formValues.category || inspiration.category);

		fetch(`${serviceConfig.apiUrl}/inspiration/${inspiration._id}`, {
			method: "PUT",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setInspirations((prev) =>
						prev.map((insp) =>
							insp._id === inspiration._id ? data.data : insp
						)
					);
					window.location.reload();
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error updating inspiration:", error));

		setIsManagePanelOpen(false);
	};

	const submitInspiration = () => {
		if (currentInspiration) {
			editInspiration(currentInspiration);
		} else {
			const formData = new FormData();

			// Ensure uploadedImageSrc is a File object
			if (uploadedImageSrc instanceof File) {
				formData.append("image", uploadedImageSrc); // Append the file directly
			} else {
				console.error("Uploaded image is not a valid file.");
				return;
			}

			formData.append("name", formValues.name);
			formData.append("description", formValues.description);
			formData.append("category", formValues.category);

			fetch(`${serviceConfig.apiUrl}/inspiration`, {
				method: "POST",
				body: formData,
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setInspirations((prev) => [...prev, data.data]);
						setIsManagePanelOpen(false);
						setFormValues({ name: "", description: "", category: "Trending" });
						setUploadedImageSrc(undefined);
					} else {
						console.error(data.message);
					}
				})
				.catch((error) => console.error("Error creating inspiration:", error));
		}
	};

	const handleDeleteInspiration = (inspirationId: number) => {
		setDeletingInspirationId(inspirationId);
		setIsDeleteModalOpen(true);
	};

	const confirmDeleteInspiration = () => {
		fetch(`${serviceConfig.apiUrl}/inspiration/${deletingInspirationId}`, {
			method: "DELETE",
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setInspirations(
						inspirations.filter(
							(inspiration) => inspiration._id !== deletingInspirationId
						)
					);
				} else {
					console.error(data.message);
				}
			})
			.catch((error) => console.error("Error deleting inspiration:", error));

		setIsDeleteModalOpen(false);
	};

	return (
		<div className="grid gap-8 p-8">
			<div className="grid grid-cols-maxTwo place-content-between place-items-center">
				<div className="grid gap-1">
					<Htag tag="h2" type="medium">
						Inspiration List
					</Htag>
					<P size="p2" type="regular" className="text-passiveTextColor">
						Manage and track all your inspirations.
					</P>
				</div>
				<Button
					appearance="main"
					icon={<PlusIcon />}
					onClick={handleAddInspiration}
				>
					Add Inspiration
				</Button>
			</div>
			<div className="grid grid-cols-1 gap-4">
				{inspirations.map((inspiration) => (
					<InspirationCard
						key={inspiration._id}
						imageSrc={inspiration.imageUrl}
						name={inspiration.name}
						description={inspiration.description}
						category={inspiration.category}
						likeCount={inspiration.likeCount}
						onEdit={() => handleEditInspiration(inspiration)}
						onDelete={() => handleDeleteInspiration(inspiration._id)}
					/>
				))}
			</div>
			<ManagePanel
				isOpen={isManagePanelOpen}
				onClose={closeManagePanel}
				label={currentInspiration ? "Edit Inspiration" : "Add Inspiration"}
				onSave={submitInspiration}
				onDiscard={closeManagePanel}
			>
				<div className="grid gap-6">
					<Input
						withlabel={true}
						appearance="default"
						type="text"
						name="name"
						label="Name"
						placeholder="Enter name..."
						value={formValues.name}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								name: e.target.value,
							}))
						}
					/>
					<Textarea
						withlabel={true}
						name="description"
						label="Description"
						value={formValues.description}
						onChange={(e) =>
							setFormValues((prev) => ({
								...prev,
								description: e.target.value,
							}))
						}
					/>
					<Select
						label="Category"
						defaultSelectLabel={formValues.category}
						onSelect={(value) =>
							setFormValues((prev) => ({
								...prev,
								category: value,
							}))
						}
					>
						<SelectItem value="Trending">Trending</SelectItem>
						<SelectItem value="New Feeds">New Feeds</SelectItem>
					</Select>

					<ImageUpload
						singleUpload={true}
						onImageUpload={(file) => {
							if (file instanceof File) {
								setUploadedImageSrc(file); // Save the actual file, not the URL
							}
						}}
						label="Inspiration"
					/>
				</div>
			</ManagePanel>
			<Modal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				title="Delete Inspiration"
				desc="Are you sure you want to delete this inspiration? This action cannot be undone."
				onConfirm={confirmDeleteInspiration}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</div>
	);
};

export default InspirationPage;
