import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "i18n";
import { useTranslation } from "react-i18next";
// import { ToastContainer, Zoom } from 'react-toastify';

import { AppRoutes, routeConstants } from "navigation";
import { ErrorBoundary } from "modules/errors";

import Sidebar from "components/Sidebar/Sidebar";
import LoginPage from "pages/LoginPage/LoginPage";
import Spinner from "modules/loaders/Spinner/Spinner";

const RoutesWithoutSidebar: React.FC = () => {
	const location = useLocation();
	const isLoginPage = location.pathname === routeConstants.login.route;

	if (isLoginPage) {
		return <LoginPage />;
	}

	return (
		<div className="grid grid-cols-1 pl-[280px]">
			<Sidebar />
			<AppRoutes />
		</div>
	);
};

const App: React.FC = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState(false);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<ErrorBoundary>
			<Router>
				{/* <ToastContainer
					position="top-right"
					autoClose={3000}
					limit={3}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="dark"
					transition={Zoom}
				/> */}

				<RoutesWithoutSidebar />
			</Router>
		</ErrorBoundary>
	);
};

export default App;
