import React, { useLayoutEffect } from "react";
import { ReactComponent as CloseIcon } from "common/assets/icons/x.svg";
import { Htag } from "common/Htag/Htag";
import { Button } from "common/Button/Button";
import { motion } from "framer-motion";
import { ManagePanelProps } from "./ManagePanel.props";
import { useTranslation } from "react-i18next";
import Spinner from "modules/loaders/Spinner/Spinner";

const ManagePanel: React.FC<ManagePanelProps> = ({
	isOpen,
	onClose,
	label,
	onSave,
	onDiscard,
	children,
	width,
	isLoading,
}) => {
	const { t } = useTranslation();
	useLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.removeProperty("overflow");
		};
	}, [isOpen]);

	const panelVariants = {
		open: { x: 0, transition: { duration: 0.3 } },
		closed: { x: "100%", transition: { duration: 0.3 } },
		exit: { x: "100%", transition: { duration: 0.3 } },
	};

	return (
		<>
			<div
				className={`${
					isOpen ? "fixed inset-0 z-40" : "hidden"
				} backdrop-blur-sm bg-[rgba(47,47,47,0.65)]`}
				onClick={onClose}
			></div>
			{isOpen && (
				<motion.div
					key="managePanel"
					initial="closed"
					animate="open"
					exit="exit"
					variants={panelVariants}
					className={`fixed top-0 bottom-0 right-0 ${
						width ? width : "w-[475px]"
					} bg-whiteColor flex flex-col z-50`}
				>
					<div className="z-10 flex gap-6 px-8 py-6 bg-white border-b border-strokeColor place-items-center">
						<CloseIcon onClick={onClose} className="cursor-pointer" />
						<Htag tag="h3" type="medium" className="cursor-default">
							{label}
						</Htag>
					</div>
					<div className="flex-grow px-8 py-6 overflow-y-auto">{children}</div>
					<div className="grid w-full grid-cols-2 gap-6 px-8 py-6 bg-white border-t border-strokeColor">
						<Button appearance="white" onClick={onDiscard} width="w-full">
							Discard
						</Button>
						{isLoading ? (
							<Spinner inModal={true} />
						) : (
							<Button appearance="main" onClick={onSave} width="w-full">
								Save
							</Button>
						)}
					</div>
				</motion.div>
			)}
		</>
	);
};

export default ManagePanel;
