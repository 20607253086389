import { Navigate, Route, Routes } from "react-router-dom";

import { routeConstants } from "navigation";
import WaitlistPage from "pages/WaitlistPage/WaitlistPage";
import UserManagementPage from "pages/UserManagementPage/UserManagementPage";
import UserManagementDetailPage from "pages/UserManagementDetailPage/UserManagementDetailPage";
import ConfigPage from "pages/ConfigPage/ConfigPage";
import ProductList from "pages/ProductList/ProductList";
import InspirationPage from "pages/InspirationPage/InspirationPage";

export const UserRoutes = () => {
	return (
		<Routes>
			<Route path={routeConstants.waitlist.route} element={<WaitlistPage />} />
			<Route
				path={routeConstants.userManagement.route}
				element={<UserManagementPage />}
			/>
			<Route
				path={`${routeConstants.userManagementDetails.route}/:id`}
				element={<UserManagementDetailPage />}
			/>
			<Route path={`${routeConstants.config.route}`} element={<ConfigPage />} />
			<Route
				path={`${routeConstants.products.route}`}
				element={<ProductList />}
			/>
			<Route
				path={`${routeConstants.inspiration.route}`}
				element={<InspirationPage />}
			/>

			<Route path="*" element={<Navigate to={routeConstants.root.route} />} />
		</Routes>
	);
};
