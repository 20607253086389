import { Htag } from "common/Htag/Htag";
import { P } from "common/P/P";
import StyleManagerCard from "components/StyleManagerCard/StyleManagerCard";
import { serviceConfig } from "configs";
import Spinner from "modules/loaders/Spinner/Spinner";
import React, { useEffect, useState } from "react";

const ConfigPage: React.FC = () => {
	const [productTypes, setProductTypes] = useState<string[]>([]);
	const [lengths, setLengths] = useState<string[]>([]);
	const [shapes, setShapes] = useState<{ name: string; image: string }[]>([]);
	const [sizes, setSizes] = useState<string[]>([]);
	const [collections, setCollections] = useState<string[]>([]);
	const [colors, setColors] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleAddProductType = (newType: string) => {
		setProductTypes((prevTypes) => [...prevTypes, newType]);
	};

	const handleAddLength = (newLength: string) => {
		setLengths((prevLengths) => [...prevLengths, newLength]);
	};

	const handleAddShape = (newShape: { name: string; image: string }) => {
		setShapes((prevShapes) => [...prevShapes, newShape]);
	};

	const handleAddSize = (newSize: string) => {
		setSizes((prevSizes) => [...prevSizes, newSize]);
	};

	const handleAddCollection = (newCollection: string) => {
		setCollections((prevCollections) => [...prevCollections, newCollection]);
	};

	const handleAddColor = (newColor: string) => {
		setColors((prevColors) => [...prevColors, newColor]);
	};

	useEffect(() => {
		const fetchInitialData = async () => {
			setIsLoading(true);
			try {
				const [
					productTypesResponse,
					lengthsResponse,
					shapesResponse,
					sizesResponse,
					collectionsResponse,
					colorsResponse,
				] = await Promise.all([
					fetch(`${serviceConfig.apiUrl}/config/productType`),
					fetch(`${serviceConfig.apiUrl}/config/lengthType`),
					fetch(`${serviceConfig.apiUrl}/config/shape`),
					fetch(`${serviceConfig.apiUrl}/config/sizeType`),
					fetch(`${serviceConfig.apiUrl}/config/collectionType`),
					fetch(`${serviceConfig.apiUrl}/config/colorType`),
				]);

				const productTypesData = await productTypesResponse.json();
				const lengthsData = await lengthsResponse.json();
				const shapesData = await shapesResponse.json();
				const sizesData = await sizesResponse.json();
				const collectionsData = await collectionsResponse.json();
				const colorsData = await colorsResponse.json();

				setProductTypes(productTypesData.data);
				setLengths(lengthsData.data);
				setShapes(shapesData.data);
				setSizes(sizesData.data);
				setCollections(collectionsData.data);
				setColors(colorsData.data);
				setIsLoading(false);
			} catch (error) {
				console.error("Failed to fetch initial data:", error);
				setIsLoading(false);
			}
		};

		fetchInitialData();
	}, []);

	return (
		<div className="p-8">
			{isLoading && <Spinner />}
			<div className="grid gap-1">
				<Htag tag="h2" type="medium">
					Configuration
				</Htag>
				<P size="p2" type="regular" className="text-passiveTextColor">
					Track, manage and forecast your config system.
				</P>
			</div>
			<div className="grid grid-cols-2 gap-6 mt-8">
				<StyleManagerCard
					styles={productTypes}
					onAddStyle={handleAddProductType}
					label="Product type"
					managePanelLabel="Add product type"
					managePanelTitle="You can add type there"
					managePanelInputLabel="Type"
				/>
				<StyleManagerCard
					styles={lengths}
					onAddStyle={handleAddLength}
					label="Length"
					managePanelLabel="Add length"
					managePanelTitle="You can add length there"
					managePanelInputLabel="Length"
				/>
				<StyleManagerCard
					styles={shapes}
					onAddStyle={handleAddShape}
					label="Shape"
					managePanelLabel="Add shape"
					managePanelTitle="Add a shape with an image"
					managePanelInputLabel="Shape"
				/>
				<StyleManagerCard
					styles={sizes}
					onAddStyle={handleAddSize}
					label="Size"
					managePanelLabel="Add size"
					managePanelTitle="You can add size there"
					managePanelInputLabel="Size"
				/>
				<StyleManagerCard
					styles={collections}
					onAddStyle={handleAddCollection}
					label="Collection"
					managePanelLabel="Add collection"
					managePanelTitle="You can add collection there"
					managePanelInputLabel="Collection"
				/>
				<StyleManagerCard
					styles={colors}
					onAddStyle={handleAddColor}
					label="Color"
					managePanelLabel="Add color"
					managePanelTitle="You can add color there"
					managePanelInputLabel="Color"
				/>
			</div>
		</div>
	);
};

export default ConfigPage;
