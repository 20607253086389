import React from "react";
import { InspirationCardProps } from "./InspirationCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

// Truncate function
function truncateText(text: string, maxLength: any) {
	if (text.length > maxLength) {
		return text.substring(0, maxLength) + "...";
	}
	return text;
}

const InspirationCard: React.FC<InspirationCardProps> = ({
	imageSrc,
	name,
	description,
	likeCount,
	category,
	onEdit,
	onDelete,
}) => {
	console.log("IMages,", `${serviceConfig.apiUrl}${imageSrc}`);
	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
			<div className="grid gap-4 grid-cols-maxFr place-items-start">
				<img
					src={`${serviceConfig.apiUrl}${imageSrc}`}
					alt={name}
					className="object-cover w-24 h-24 rounded-md"
				/>
				<div className="max-w-lg">
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Name:
						</P>
						<P size="p2" type="regular">
							{name}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Description:
						</P>
						<P size="p2" type="regular">
							{truncateText(description, 130)}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Like Count:
						</P>
						<P size="p2" type="regular">
							{likeCount}
						</P>
					</div>
					<div className="grid gap-2 grid-cols-maxTwo">
						<P size="p2" type="medium">
							Category:
						</P>
						<div className="flex flex-wrap gap-2">{category}</div>
					</div>
				</div>
			</div>
			<Actions onEdit={onEdit} onDelete={onDelete} />
		</div>
	);
};

export default InspirationCard;
