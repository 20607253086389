import React from "react";
import { ProductCardProps } from "./ProductCard.props";
import Actions from "common/Actions/Actions";
import { P } from "common/P/P";
import { serviceConfig } from "configs";

const ProductCard: React.FC<ProductCardProps> = ({
	imageSrc,
	name,
	description,
	price,
	discountPrice,
	discountPercentage,
	productType,
	length,
	shape,
	size,
	collection,
	color,
	stock,
	onEdit,
	onDelete,
}) => {
	return (
		<div className="grid w-full px-4 py-3 bg-white border rounded-lg shadow-xs grid-cols-maxTwo place-content-between place-items-center border-strokeColor">
			<div className="grid gap-4 grid-cols-maxTwo place-items-center">
				<img
					src={`${serviceConfig.apiUrl}/get-image/${imageSrc}`}
					alt={name}
					className="object-cover w-24 h-24 rounded-md"
				/>
				<div>
					<P size="p2" type="medium">
						Name: {name}
					</P>
					<P size="p2" type="regular">
						Description: {description}
					</P>
					<P size="p2" type="regular">
						Price: ${price}
					</P>
					{discountPrice && (
						<P size="p2" type="regular">
							Discount Price: ${discountPrice}
						</P>
					)}
					{discountPercentage && (
						<P size="p2" type="regular">
							Discount: {discountPercentage}%
						</P>
					)}
				</div>
			</div>
			<Actions onEdit={onEdit} onDelete={onDelete} />
		</div>
	);
};

export default ProductCard;
