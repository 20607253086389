import React, { useState } from "react";
import EmptyState from "common/EmptyState/EmptyState";
import ManagePanel from "common/ManagePanel/ManagePanel";
import StyleManagerItem from "common/StyleManagerItem/StyleManagerItem";
import { P } from "common/P/P";
import { ReactComponent as PlusIcon } from "common/assets/icons/plus.svg";
import { Input } from "common/Input/Input";
import { serviceConfig } from "configs";
import { toast } from "react-toastify";
import ImageUpload from "common/Image/ImageUpload";

interface StyleManagerCardProps {
	styles: any[];
	onAddStyle: (style: any) => void;
	onSave?: any;
	label: string;
	paddingY?: string;
	managePanelLabel: string;
	managePanelTitle: string;
	managePanelInputLabel: string;
}

const StyleManagerCard: React.FC<StyleManagerCardProps> = ({
	styles,
	onAddStyle,
	onSave,
	label,
	paddingY,
	managePanelLabel,
	managePanelTitle,
	managePanelInputLabel,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [newStyles, setNewStyles] = useState<string[]>(Array(6).fill(""));
	const [newShape, setNewShape] = useState<{
		name: string;
		image: File | null;
	}>({
		name: "",
		image: null,
	});
	const [isModalLoading, setIsModalLoading] = useState(false);
	const [editIndex, setEditIndex] = useState<number | null>(null);
	const [editId, setEditId] = useState<string | null>(null);

	const handleInputChange = (index: number, value: string) => {
		const updatedStyles = [...newStyles];
		updatedStyles[index] = value;
		setNewStyles(updatedStyles);
	};

	const handleShapeInputChange = (field: string, value: string | File) => {
		setNewShape((prev) => ({ ...prev, [field]: value }));
	};

	const handleAddAndSave = async () => {
		setIsModalLoading(true);
		try {
			let result: { success: boolean; data: any; message?: string } = {
				success: false,
				data: null,
			};

			if (editId) {
				// Edit existing item
				if (label === "Shape") {
					const formData = new FormData();
					formData.append("name", newShape.name);
					if (newShape.image) formData.append("image", newShape.image);

					const response = await fetch(
						`${serviceConfig.apiUrl}/config/shape/${editId}`,
						{
							method: "PUT",
							body: formData,
						}
					);
					result = await response.json();
					window.location.reload();
				} else {
					const endpointMap: Record<string, string> = {
						"Product type": "/productType",
						Length: "/lengthType",
						Size: "/sizeType",
						Collection: "/collectionType",
						Color: "/colorType",
					};
					const endpoint = endpointMap[label];
					const response = await fetch(
						`${serviceConfig.apiUrl}/config${endpoint}/${editId}`,
						{
							method: "PUT",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({ name: newStyles[0] }),
						}
					);
					result = await response.json();
					window.location.reload();
				}
				if (!result.success) {
					throw new Error(result.message || "Failed to save");
				}
				toast.success(`Successfully updated ${label.toLowerCase()}!`);
			} else {
				// Add new item
				if (label === "Shape") {
					const formData = new FormData();
					formData.append("name", newShape.name);
					if (newShape.image) formData.append("image", newShape.image);

					const response = await fetch(`${serviceConfig.apiUrl}/config/shape`, {
						method: "POST",
						body: formData,
					});
					result = await response.json();
					window.location.reload();
				} else {
					const endpointMap: Record<string, string> = {
						"Product type": "/productType",
						Length: "/lengthType",
						Size: "/sizeType",
						Collection: "/collectionType",
						Color: "/colorType",
					};
					const endpoint = endpointMap[label];

					for (const style of newStyles) {
						if (style) {
							const response = await fetch(
								`${serviceConfig.apiUrl}/config${endpoint}`,
								{
									method: "POST",
									headers: {
										"Content-Type": "application/json",
									},
									body: JSON.stringify({ name: style }),
								}
							);
							result = await response.json();
							window.location.reload();
							if (!response.ok) {
								throw new Error(result.message || "Failed to save");
							}
							toast.success(`Successfully added ${label.toLowerCase()}!`);
						}
					}
				}
				if (label === "Shape") {
					if (!result.success) {
						throw new Error(result.message || "Failed to save");
					}
					toast.success("Successfully added shape!");
				}
			}
			setNewStyles(Array(6).fill(""));
			setNewShape({ name: "", image: null });
			setIsOpen(false);
		} catch (error) {
			console.error("Error saving the item:", error);
			toast.error("Save error!");
		} finally {
			setIsModalLoading(false);
		}
	};

	const handleEditItem = (index: number) => {
		setEditIndex(index);
		setIsOpen(true);
		if (label === "Shape") {
			setNewShape(styles[index]);
		} else {
			const updatedStyles = [...newStyles];
			updatedStyles[0] = styles[index].name;
			setNewStyles(updatedStyles);
		}
		setEditId(styles[index]._id);
	};

	const handleDeleteItem = async (index: number) => {
		try {
			const item = styles[index];
			const endpointMap: any = {
				"Product type": "/productType",
				Length: "/lengthType",
				Size: "/sizeType",
				Collection: "/collectionType",
				Color: "/colorType",
				Shape: "/shape",
			};
			const endpoint = endpointMap[label];
			const response = await fetch(
				`${serviceConfig.apiUrl}/config${endpoint}/${item._id}`,
				{
					method: "DELETE",
				}
			);
			const result = await response.json();
			if (!response.ok) {
				throw new Error(result.message || "Failed to delete");
			}
			toast.success(`Successfully deleted ${label.toLowerCase()}!`);
			window.location.reload();
		} catch (error) {
			console.error("Error deleting the item:", error);
			toast.error("Delete error!");
		}
	};

	return (
		<div>
			<div className="grid max-w-[629px] gap-8 p-6 border rounded-lg shadow-xs border-strokeColor">
				<div className="grid grid-cols-maxTwo place-content-between place-items-center">
					<P size="p1" type="medium">
						{label}
					</P>

					<button
						className="grid gap-1 text-textColor grid-cols-maxTwo place-items-center"
						onClick={() => {
							setIsOpen(true);
							setEditIndex(null);
							setEditId(null);
						}}
					>
						<PlusIcon className="stroke-textColor" />
						<P size="p2" type="medium" className="cursor-pointer">
							Add
						</P>
					</button>
				</div>
				<div
					className={`${
						styles.length > 0 ? "flex flex-wrap gap-3" : "place-content-stretch"
					}`}
				>
					{styles.length > 0 ? (
						styles.map((style: any, idx) => (
							<StyleManagerItem
								key={idx}
								label={label === "Shape" ? style.name : style.name}
								paddingY={paddingY}
								onEdit={() => handleEditItem(idx)}
								onDelete={() => handleDeleteItem(idx)}
							/>
						))
					) : (
						<EmptyState title="Empty..." className="place-content-stretch" />
					)}
				</div>
			</div>

			{isOpen && (
				<ManagePanel
					onClose={() => setIsOpen(false)}
					onSave={handleAddAndSave}
					isOpen={isOpen}
					label={managePanelLabel}
					onDiscard={() => setIsOpen(false)}
					width="w-[515px]"
					isLoading={isModalLoading}
				>
					<div className="grid gap-6">
						<P size="p2" type="regular">
							{managePanelTitle}
						</P>
						<div className="grid gap-4">
							{label === "Shape" ? (
								<>
									{newShape.image && (
										<img
											src={URL.createObjectURL(newShape.image)}
											alt="images"
											className="object-cover w-44 h-44"
										/>
									)}
									<Input
										withlabel={true}
										appearance="default"
										type="text"
										name="shape-name"
										label="Shape Name"
										value={newShape.name}
										onChange={(e) =>
											handleShapeInputChange("name", e.target.value)
										}
									/>
									<ImageUpload
										onImageUpload={(file) => {
											const imageFile = Array.isArray(file) ? file[0] : file;
											if (imageFile instanceof File) {
												handleShapeInputChange("image", imageFile);
											} else {
												console.error("Uploaded image is not a File instance");
											}
										}}
										singleUpload={true}
										label="Shape Image"
									/>
								</>
							) : (
								<>
									<Input
										withlabel={true}
										appearance="default"
										type="text"
										name="style-input-0"
										label={`${managePanelInputLabel}`}
										value={newStyles[0]}
										onChange={(e) => handleInputChange(0, e.target.value)}
									/>
								</>
							)}
						</div>
					</div>
				</ManagePanel>
			)}
		</div>
	);
};

export default StyleManagerCard;
