import React from "react";

import { ButtonProps } from "./Button.props";

export const Button = ({
	children,
	appearance,
	icon = "none",
	className = "",
	width = "max",
	paddingX = "px-16",
	center = true,
	...props
}: ButtonProps): JSX.Element => {
	return (
		<button
			className={`${
				appearance === "main"
					? "bg-mainColor text-white border border-mainColor"
					: appearance === "white"
					? "bg-white text-textColor border border-strokeColor"
					: ""
			} 
			w-${width}
			${
				center
					? "place-content-center place-items-center"
					: "place-content-start place-items-center"
			}
				py-3 ${paddingX} rounded-lg shadow-xs ${
				icon && "grid grid-cols-maxTwo gap-2"
			} ${className}`}
			{...props}
		>
			{icon !== "none" && <>{icon}</>}
			{children}
		</button>
	);
};
